import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import get from "lodash/get"
import Layout from "../components/layout"
import ComposedContent from "../components/ComposedContent"

const PageTemplate = props => {
  const page = get(props, "data.contentfulPage")
  const siteTitle = get(props, "data.site.siteMetadata.title")

  return (
    <Layout>
      <Helmet title={`${page.title} | ${siteTitle}`} />
      <ComposedContent components={page.components} />
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query pageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      components {
        __typename
        ...textComponent
        ...headingComponent
        ...scrubberComponent
        ...storageTypeCardComponent
        ...dividerComponent
        ...piecetypeDirectoryComponent
        ...buildsListingComponent
        ...buildersListingComponent
        ...blogsListingComponent
        ...buildCardFragment
        ...imageComponent
        ...mediaCardComponent
      }
    }
  }
`
